.book_blurb {
  margin: 0 20px 10px;
}

.book_blurb li {
  margin-bottom: 10px;
  text-align: left;
}

#book_blurb_and_buy {
  text-align: center;
}

.book_buy {
  border: 1px solid #a11025;
  display: inline-block;
  margin: 0;
  padding: 10px;
}

.book_cover {
  margin: 20px auto;
  max-width: 500px;
}

.book_edit {
  display: inline-block;
  line-height: 27px;
  padding-left: 10px;
}

.book_info {
  color: #d6d6d6;
  font-size: 0.9em;
  margin: 0 auto;
}

.book_title_and_info {
  text-align: center;
}

.book_title h2 {
  display: inline-block;
  padding-bottom: 3px;
}

.bookPreview h2,
.bookPreview h4,
.bookPreview p {
  text-align: center;
}

.bookPreview h2 {
  margin: 0 auto;
  width: 150px;
}

.bookPreview img {
  margin: 5px auto 10px;
}

.bookPreview p {
  margin: 0;
}

.bookPreview h4 {
  margin: 10px auto;
}

@media screen and (min-width: 525px) {
  .bookPreview {
    height: 390px;
  }
}

@media screen and (min-width: 620px) {
  #BookSingle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 65px 1fr;
  }
  #book_blurb_and_buy {
    grid-area: 2 / 2 / 3 / 3;
    margin: 20px auto;
  }
  .book_cover {
    grid-area: 2 / 1 / 3 / 2;
  }
  .book_title_and_info {
    grid-area: 1 / 1 / 2 / 2;
    margin-left: 17px;
    text-align: left;
  }
}

@media screen and (min-width: 1020px) {
  #BookSingle {
    grid-template-columns: 410px 1fr;
  }
  .book_cover {
    max-width: 400px;
  }
}
