* {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: baseline;
}

/* a:active {
  color: #a11025; 
  text-decoration: underline;
} */

a:link {
  color: #a6d7fa;
}

a:visited {
  color: #c2c2c2;
}

.App {
  background-color: black;
  color: white;
  display: grid;
  height: 100vh;
  grid-template-columns: 100%;
  grid-template-rows: 112px 45px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 0;
  padding: 2%;
  text-align: left;
  width: 100%;
}

.centre {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

figcaption {
  color: darkgrey;
  font-style: italic;
}

.gallery {
  display: flex;
  flex: 1 1 0px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  text-decoration: none;
}

.gallery a {
  text-decoration: none;
}

.galleryItem {
  align-items: center;
  border: 1px solid #a11025;
  border-radius: 3px;
  justify-content: center;
  margin: 14px auto;
  padding: 10px;
  width: 230px;
}

.gallery_pic h3 {
  margin: 5px 0 10px;
}

.gallery_pic img {
  border: 1px #c2c2c2 solid;
  border-radius: 3px;
  padding: 0;
  vertical-align:bottom;
}

h1 {
  font-size: 1.4em;
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 1.1em;
}

main {
  grid-area: 3 / 1 / 4 / 2;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 5px 60px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 93%;
}

main p {
  margin: 10px 0;
}

#PiGrid {
  width: 99%;
}

.piSquare {
  display: inline;
  float: left;
  height: 7px;
  width: 7px;
}

.series__heading h2 {
  margin-top: 0;
  padding-bottom: 0;
}

.series__heading p {
  color: darkgrey;
  margin-top: 0;
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 525px) {
  .galleryItem {
    margin: 14px;
    width: 180px;
  }
}

@media screen and (min-width: 620px) {
  .App {
    grid-template-columns: 314px 1fr;
    grid-template-rows: 120px 1fr;
  }
  .gallery {
    justify-content: left;
  }
  main {
    grid-area: 2 / 1 / 3 / 3;
  }
}