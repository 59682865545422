#bio {
    margin-top: 14px;
}

#cnmc-photo img {
    margin: 10px 0;
    max-width: 415px;
    width: 100%;
}

#Introduction p {
  text-align: left;
}

@media screen and (min-width: 620px) {
    #bio {
      grid-area: 2 / 2 / 3 / 3;
    }
    #cnmc-photo {
      grid-area: 2 / 1 / 3 / 2;
    }
    #Introduction {
      display: grid;
      grid-template-columns: 420px 1fr;
      grid-auto-rows: max-content;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
    }
    #short_intro {
      grid-area: 1 / 1 / 2 / 3;
    }
  }