.hexagon {
  border-right: 5px solid transparent;
  float: left;
  height: 50px;
  max-width: 33%;
  width: 50px;
}

.hexagon img {
  height: 50px;
  width: 50px;
}

#Header {
  grid-area: 1 / 1 / 2 / 2;
  width: 314px;
}

#Logo {
  float: left;
}

#Logo img {
  margin: 0 5px;
  width: 90px;
}

.nav_art {
  height: 90px;
}

#nav-portfolio a:link {
  text-decoration: none;
}

#NavSite {
  grid-area: 2 / 1 / 3 / 2;
  margin: -10px 0 0;
  overflow-x: hidden;
  padding: 0;
  text-align: center;
  width: 96vw;
}

.nav-site-link {
  display: inline;
  margin: 3px 14px;
}

#nav-social-two {
  margin-left: 27px;
}

.nav-section {
  float: left;
  height: 50px;
}

.searchSelect {
  background-color: #d6d6d6;
  border: 1px solid #a11025;
  font-weight: bold;
  height: 20px;
  margin: 0 10px 5px 0;
}

@media screen and (min-width: 620px) {
  #NavSite {
    grid-area: 1 / 2 / 2 / 3;
    margin: 0;
    max-width: 486px;
    text-align: left;
    width: 90%;
  }
}
