#Art {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  margin: 0;
}

.art__close_ups {
  margin-top: 10px;
}

.art__close_ups h2 {
  margin-bottom: 14px;
}

.art__close_up img {
  border: 1px #c2c2c2 solid;
  border-radius: 3px;
  margin-right: 10px;
  padding: 0;
  width: 90px;
}

.art__gallery {
  grid-area: 2 / 1 / 3 / 2;
}

.ArtGallery {
  padding-bottom: 10px;
}

.art__previous_button {
  background-color: #c2c2c2;
  border: 1px solid white;
  border-radius: 3px;
  box-shadow: 3px -3px #888888;
  color: black;
  padding: 6px;
}

.art__quote {
  font-style: italic;
  margin: 0 auto;
  padding: 0;
}

.art__quote_source {
  color: #d6d6d6;
  margin: 0 auto;
}

#ArtSearchBar {
  color: white;
  display: flex;
  flex: 1 1 0px;
  flex-direction: row;
  flex-wrap: wrap;
  grid-area: 1 / 1 / 2 / 2;
  justify-content: center;
}

.art__self_link {
  margin: 5px 0 0;
}

.art__self_link img {
  border: 1px #c2c2c2 solid;
  border-radius: 3px;
  width: 90px;
}

.art__self_link p {
  margin: 5px 0 0;
  padding: 0;
}

#ArtSingle {
  padding: 6px;
}

.art__title h2 {
  display: inline-block;
  padding-bottom: 3px;
}

.art__year {
  display: inline-block;
  line-height: 27px;
  padding-left: 10px;
  color: #d6d6d6;
  font-size: 0.9em;
  margin: 0 auto;
}

.landscape,
.portrait,
.square {
  border: 1px #c2c2c2 solid;
  border-radius: 3px;
  display: block;
  margin: 10px auto;
  max-width: 622px;
  width: 98%;
}

@media screen and (min-width: 620px) {
  #ArtSearchBar {
    justify-content: left;
  }
}

@media screen and (min-width: 820px) {
  .art__info {
    /* border: 1px solid red; */
    grid-area: 2 / 2 / 3 / 3;
    padding: 0 10px;
  }
  .art__pic {
    /* border: 1px solid red; */
    grid-area: 2 / 1 / 3 / 2;
    margin: 0;
  }
  .art__quote_and_source {
    margin-top: 23px;
    text-align: left;
  }
  #ArtSingle {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 26px 1fr;
  }
  .art__title {
    /* border: 1px solid red; */
    grid-area: 1 / 1 / 2 / 2;
    padding-left: 5px;
  }
  .portrait {
    max-width: 415px;
  }
  .square {
    max-width: 515px;
  }
}
