.code_date {
  font-size: 0.9em;
  text-align: center;
}

.code_date p {
  margin: 3px 0 10px;
}

.code_info {
  margin: 5px 0;
}

.code_name {
  border: 1px solid #6b6b6b;
  border-radius: 3px;
  margin: 0 14px;
  text-align: center;
}

.code_stack {
  margin-top: 10px;
}

.code_stack_logos img {
  border: 1px solid #6b6b6b;
  margin: 0;
  padding: 5px;
  width: 50px;
}

.code_stack_logos li {
  display: inline;
  float: left;
  margin-bottom: 10px;
  width: 85px;
}

.code_stack_text li {
  display: inline;
  float: left;
  font-size: 0.8em;
  margin: 7px 5px 0 0;
  text-align: center;
  width: 110px;
}

.code_tech {
  margin-top: 10px;
}

.mobile {
  margin-bottom: 10px;
}

.tagline {
  color: #b3b3b3;
  font-style: italic;
}

@media screen and (min-width: 525px) {
  .code_stack_text li {
    width: 85px;
  }
}

@media screen and (min-width: 620px) {
  #CodeSingle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 666px 1fr;
    grid-column-gap: 20px;
  }
  .desktop { 
    /* border: 1px solid #a11025; */
    grid-area: 2 / 1 / 3 / 3; 
  }
  .desktop img { 
    margin: 0 0 10px;
    max-width: 800px;
  }
  .mobile { 
    /* border: 1px solid #a11025; */
    grid-area: 1 / 2 / 2 / 3;
  }
  .mobile img {
    margin: 0;
    max-width: 300px;
  }
  #project_details { 
    /* border: 1px solid #a11025; */
    grid-area: 1 / 1 / 2 / 2; 
  }
}

@media screen and (min-width: 920px) {
  #CodeSingle {
    grid-template-columns: 1fr 314px 1fr;
    grid-template-rows: 666px;
  }
  #project_details { 
    grid-area: 1 / 1 / 2 / 2;
  }
  .mobile { 
    grid-area: 1 / 2 / 2 / 3;
  }
  .desktop { grid-area: 1 / 3 / 2 / 4; }
}